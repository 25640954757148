import { SIDE_NAV_WIDTH } from "@constants/theme";
import { Scrollbars } from "react-custom-scrollbars";
import { Layout } from "antd";

import MenuContent from "./MenuContent";

const { Sider } = Layout;

type SideNavProps = {
  navCollapsed: boolean;
};
const SideNav: React.FC<SideNavProps> = ({ navCollapsed }) => {
  const onMobileNavToggle = () => {};
  return (
    <Sider
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
      className="side-nav side-nav-dark"
    >
      <Scrollbars autoHide>
        <MenuContent onMobileNavToggle={onMobileNavToggle} />
      </Scrollbars>
    </Sider>
  );
};

export default SideNav;
