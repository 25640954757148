import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "@constants/theme";
import { Grid } from "antd";
import Image from "next/image";

const { useBreakpoint } = Grid;

import utils from "utils";

const getLogoDisplay = (isMobile: boolean, mobileLogo: boolean) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

const getLogoWidthGutter = (props: any, isMobile: boolean) => {
  const { navCollapsed } = props;

  if (isMobile && !props.mobileLogo) {
    return 0;
  }

  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props: any) => {
  const { navCollapsed, mobileLogo } = props;

  if (mobileLogo) {
    if (navCollapsed) {
      return "https://res.cloudinary.com/ddx260rti/image/upload/c_scale,w_136/v1621086161/22245_jouwvraagposten_RB_SG-01_1_ssms0j.png";
    }
    return "https://res.cloudinary.com/ddx260rti/image/upload/c_scale,w_190/v1620830795/22245_jouwvraagposten_RB-05_1_jfuzlo.png";
  }

  if (navCollapsed) {
    return "https://res.cloudinary.com/ddx260rti/image/upload/c_scale,w_35/v1622595014/22245_jouwvraagposten_RB-05_1_jfuzlo.png";
  }
  return "https://res.cloudinary.com/jouwvraagposten/image/upload/c_scale,w_190/v1622824757/22245_jouwvraagposten_RB-05_1_jfuzlo_jzomyb.png";
};

type LogoProps = {
  mobileLogo?: boolean;
  navCollapsed: boolean;
};

const Logo: React.FC<LogoProps> = (props) => {
  const { mobileLogo = false, navCollapsed } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");

  return (
    <div
      className={getLogoDisplay(isMobile, mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <img src={getLogo(props)} alt={`Jouwvraagposten logo`} />
    </div>
  );
};

export default Logo;
