type FlexProps = {
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  className?: string;
};

const Flex: React.FC<FlexProps> = (props) => {
  const { children, flexDirection, justifyContent, alignItems, className } =
    props;

  return (
    <div
      className={`d-flex ${className} ${
        flexDirection ? "flex-" + flexDirection : ""
      } ${alignItems ? "align-items-" + alignItems : ""} ${
        justifyContent ? "justify-content-" + justifyContent : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Flex;
