import { ReactElement, useState } from "react";
import { Menu } from "antd";

import { InnerAppLayout } from "@components/layout";

export interface TabMenuViews {
  [key: string]: {
    label: string;
    Icon: any;
    content: ReactElement;
  };
}

type TabMenuContent = {
  tabViews: TabMenuViews;
};

const TabMenuContent: React.FC<TabMenuContent> = ({ tabViews }) => {
  const [activeTab, setActiveTab] = useState<string>(
    Object.keys(tabViews)[0] || ""
  );

  const getSideContent = () => {
    return (
      <Menu mode="inline" selectedKeys={[activeTab]}>
        {Object.keys(tabViews).map((tabKey) => (
          <Menu.Item key={tabKey} onClick={() => setActiveTab(tabKey)}>
            {tabViews[tabKey].Icon}
            <span>{tabViews[tabKey].label}</span>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <InnerAppLayout
      sideContent={getSideContent()}
      sideContentWidth={320}
      mainContent={tabViews[activeTab].content}
    />
  );
};

export default TabMenuContent;
