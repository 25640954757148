import AuthContext from "@context/AuthContext";
import { Row, Col, Card } from "antd";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";

import { Loading } from "@components/common";

const backgroundStyle = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

type AuthLayoutProps = {
  checkAuth?: boolean;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    if (user) {
      router.push("/dashboard");
    }
  }, [user]);

  return (
    <div className="auth-container">
      <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={7}>
              <Card>
                <div className="my-4">
                  <div className="text-center">
                    <img
                      style={{ width: 50 }}
                      src="https://res.cloudinary.com/ddx260rti/image/upload/c_scale,w_250/v1620830795/22245_jouwvraagposten_RB-05_1_jfuzlo.png"
                    />

                    <div style={{ height: 50 }} />
                  </div>
                  {loading ? <Loading /> : children}
                </div>
              </Card>
              <p className="text-center">
                &copy; {new Date().getFullYear()} jouwvraagposten.nl
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
