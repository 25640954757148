import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const Loading: React.FC = () => {
  return (
    <div className={`loading text-center cover-inline`}>
      <Spin indicator={Icon} />
    </div>
  );
};

export default Loading;
