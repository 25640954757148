import { Layout, Grid, Breadcrumb } from "antd";
import { useState, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import AuthContext from "@context/AuthContext";

import HeaderNav from "./components/HeaderNav";
import SideNav from "./components/SideNav";
import MobileNav from "./components/MobileNav";

import utils from "@utils/index";
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "@constants/theme";

const { useBreakpoint } = Grid;
const { Content } = Layout;

type AppLayoutProps = {
  pageBreadcrumb?: string[];
};

const AppLayout: React.FC<AppLayoutProps> = ({ children, pageBreadcrumb }) => {
  const { user, loading } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    if (user === null && !loading) {
      router.push("/login");
    }
  }, [user, loading]);

  const [navCollapsed, setNavCollapsed] = useState(true);
  const [mobileNav, setMobileNav] = useState(false);

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile: boolean = !screens.includes("lg");

  const getLayoutGutter = () => {
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const getLayoutDirectionGutter = () => {
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <Layout>
      <HeaderNav
        mobileNav={mobileNav}
        onMobileNavToggle={setMobileNav}
        toggleCollapsedNav={setNavCollapsed}
        navCollapsed={navCollapsed}
        isMobile={isMobile}
      />
      <Layout className="app-container">
        {!isMobile && <SideNav navCollapsed={navCollapsed} />}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className="app-content">
            {pageBreadcrumb && (
              <>
                <Breadcrumb separator=">">
                  {pageBreadcrumb.map((text) => (
                    <Breadcrumb.Item>{text}</Breadcrumb.Item>
                  ))}
                </Breadcrumb>
                <br />
              </>
            )}

            <Content>{children}</Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && (
        <MobileNav mobileNav={mobileNav} onMobileNavToggle={setMobileNav} />
      )}
    </Layout>
  );
};

export default AppLayout;
