import { Drawer } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";

import Flex from "@components/common/Flex";
import MenuContent from "./MenuContent";
import Logo from "./Logo";
import * as colors from "@constants/colors";

type MobileNavProps = {
  mobileNav: boolean;
  onMobileNavToggle: (value: boolean) => void;
};

const MobileNav: React.FC<MobileNavProps> = (props) => {
  const { mobileNav, onMobileNavToggle } = props;

  const onClose = () => {
    onMobileNavToggle(false);
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{
        padding: 5,
        backgroundColor: colors.PRIMARY,
        color: "white",
      }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo navCollapsed mobileLogo={mobileNav} />
          <div className="nav-close" onClick={onClose}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent onMobileNavToggle={onClose} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

export default MobileNav;
