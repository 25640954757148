import { Menu, Badge } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  AuditOutlined,
  CarryOutOutlined,
  ContactsOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  ApiOutlined,
  SettingOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { useRouter } from "next/router";

import * as colors from "@constants/colors";
import { useContext, useEffect, useState } from "react";
import AuthContext from "@context/AuthContext";
import ClientContext from "@context/ClientContext";
const { SubMenu } = Menu;

type NavTree = {
  key: string;
  path?: string;
  title: string;
  Icon?: any;
  badge?: boolean;
  submenu: NavTree[];
  adminOnly?: boolean;
  action?: () => void;
};

type MenuContentProps = {
  onMobileNavToggle: (value: boolean) => void;
};
const MenuContent: React.FC<MenuContentProps> = ({}) => {
  const router = useRouter();

  const { user } = useContext(AuthContext);
  const { clients, stats } = useContext(ClientContext);
  const [navConfig, setNavConfig] = useState<NavTree[]>([]);

  useEffect(() => {
    const navigationConfig: NavTree[] = [
      {
        key: "/dashboard",
        path: "/dashboard",
        title: "Dashboard",
        Icon: DashboardOutlined,
        submenu: [],
      },
      {
        key: "/goedkeuren-afkeuren",
        path: "/goedkeuren-afkeuren",
        title: "Goedkeuren / Afkeuren",
        Icon: CarryOutOutlined,
        badge: clients
          ?.map((client) => {
            if (!stats || !stats[client.id])
              return { ...client, statistics: { processing: 0 } };

            return { ...client, statistics: stats[client.id] };
          })
          .some(
            (client) =>
              client.statistics?.processing && client.statistics.processing > 0
          ),
        submenu: [],
      },
      {
        key: "/klanten",
        path: "/klanten",
        title: "Klanten",
        Icon: ContactsOutlined,
        submenu: [],
      },
      {
        key: "/gebruikers",
        path: "/gebruikers",
        title: "Gebruikers",
        Icon: UsergroupAddOutlined,
        adminOnly: true,
        submenu: [],
      },
      {
        key: "/abonnementen",
        path: "/abonnementen",
        title: "Abonnementen",
        Icon: ContainerOutlined,
        adminOnly: true,
        submenu: [],
      },
      {
        key: "/instellingen",
        path: "/instellingen",
        title: "Instellingen",
        Icon: SettingOutlined,
        adminOnly: true,
        submenu: [],
      },

      {
        key: "integrations",
        path: "",
        title: "Integraties",
        adminOnly: true,
        submenu: [
          {
            key: "/integraties/boekhoudprogrammas",
            path: "/integraties/boekhoudprogrammas",
            title: "Boekhoudprogramma's",
            Icon: ApiOutlined,
            adminOnly: true,
            submenu: [],
          },
        ],
      },
      {
        key: "account",
        path: "/",
        title: "Account",
        Icon: DashboardOutlined,
        submenu: [
          {
            key: "/account/gebruikersprofiel",
            path: "/account/gebruikersprofiel",
            title: "Gebruikersprofiel",
            Icon: UserOutlined,
            submenu: [],
          },
          {
            key: "/account/bedrijfsprofiel",
            path: "/account/bedrijfsprofiel",
            title: "Bedrijfsprofiel",
            Icon: AuditOutlined,
            adminOnly: true,
            submenu: [],
          },
          {
            key: "/account/logout",
            path: "/account/logout",
            title: "Uitloggen",
            Icon: LogoutOutlined,
            submenu: [],
          },
        ],
      },
    ];

    setNavConfig(navigationConfig);
  }, [stats, clients, user]);

  const renderSubMenu = (menu: NavTree) => {
    if (!user) return null;

    if (menu.adminOnly && user.role !== "admin") return null;

    return (
      <SubMenu
        icon={menu.Icon ? <menu.Icon /> : null}
        key={menu.key}
        title={menu.title}
      >
        {menu.submenu.map((subMenu) => renderMenuItem(subMenu))}
      </SubMenu>
    );
  };

  const renderMenuItem = (menu: NavTree) => {
    if (!user) return null;

    if (menu.adminOnly && user.role !== "admin") return null;
    return (
      <Menu.Item key={menu.key} onClick={() => router.push(menu.path!)}>
        {menu.Icon && <menu.Icon />}
        <span>{menu.title}</span>
        {menu.badge && <Badge status={"error"} style={{ marginLeft: 10 }} />}
      </Menu.Item>
    );
  };

  const renderMenuItemGroup = (menu: NavTree) => {
    return (
      <Menu.ItemGroup key={menu.key} title={menu.title}>
        {menu.submenu.map((subMenu) =>
          subMenu.submenu.length > 0
            ? renderSubMenu(subMenu)
            : renderMenuItem(subMenu)
        )}
      </Menu.ItemGroup>
    );
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      style={{
        height: "100%",
        borderRight: 0,
        backgroundColor: colors.PRIMARY,
      }}
      defaultSelectedKeys={[router.pathname]}
      selectedKeys={[router.pathname]}
    >
      {navConfig.map((menu) =>
        menu.submenu.length > 0
          ? renderMenuItemGroup(menu)
          : renderMenuItem(menu)
      )}
    </Menu>
  );
};

export default MenuContent;
