import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const { Header } = Layout;
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "@constants/theme";
import Logo from "./Logo";
// import NavPanel from "./NavPanel";

type HeaderNavProps = {
  isMobile: boolean;
  navCollapsed: boolean;
  toggleCollapsedNav: (value: boolean) => void;
  onMobileNavToggle: (value: boolean) => void;
  mobileNav: boolean;
};

const HeaderNav: React.FC<HeaderNavProps> = (props) => {
  const {
    isMobile,
    navCollapsed,
    toggleCollapsedNav,
    onMobileNavToggle,
    mobileNav,
  } = props;

  useEffect(() => {
    getNavWidth();
  }, [navCollapsed]);

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = false;

  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }

    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  return (
    <Header className={`app-header dark`}>
      <div className="app-header-wrapper">
        {/* TODO Logo here! */}
        <Logo mobileLogo={mobileNav} navCollapsed={navCollapsed} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              <li
                className="ant-menu-item ant-menu-item-only-child"
                onClick={onToggle}
              >
                {navCollapsed || isMobile ? (
                  <MenuUnfoldOutlined className="nav-icon" />
                ) : (
                  <MenuFoldOutlined className="nav-icon" />
                )}
              </li>
            </ul>
          </div>
          <div className="nav-right"></div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderNav;
